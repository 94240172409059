<template>
    <div>
        <el-button type="primary" @click="back">返回</el-button>
        <div id="map_container" style="height: 70vh;"></div>
        <el-button type="primary" @click="selectRoad(1)">线路1</el-button>
        <el-button type="primary" @click="selectRoad(2)">线路2</el-button>
        <el-button type="primary" @click="selectRoad(3)">线路3</el-button>
    </div>
</template>
  
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
    data() {
        return {
            driving: '',
            map: '',
        }
    },
    mounted() {
        window._AMapSecurityConfig = {
            securityJsCode:'d90de9df7caf7bfa2b5f4d332ded9457',
        }
        AMapLoader.load({
            key: 'c6c01b7f4ed2983c06d718bcce32e987',
            // key: 'd90de9df7caf7bfa2b5f4d332ded9457',
            version: '2.0',
            plugins: ['AMap.PolyEditor','AMap.Driving']
        }).then((AMap) => {
            // 创建地图实例
            this.map = new AMap.Map('map_container', {
                zoom: 8, // 缩放级别
                center: [104.32198, 35.14261],// 地图中心点坐标
            })

            // 创建驾车路线规划器
            this.driving = new AMap.Driving({
                map: this.map,
                // panel: '1'
            });
            const waypoints = [
                new AMap.LngLat(105.34247 , 34.73830), // 甘谷
                new AMap.LngLat(105.24900 , 34.75189), // 甘谷收费站
                new AMap.LngLat(105.11528 , 34.76269), // 磐安县
                new AMap.LngLat(105.24307 , 34.68282), // 武家河镇
                new AMap.LngLat(105.34247 , 34.73830) // 甘谷
            ]
            // 根据起点、终点和途经点进行路线规划
            this.driving.search(
                new AMap.LngLat(105.34247, 34.73830), // 起点
                new AMap.LngLat(105.34247, 34.73830), // 终点
                { waypoints: waypoints },
                function (status, result) {
                    console.log(status,result)
                    if (status === 'complete') {
                        console.log('路线规划成功');
                    } else {
                        console.log('路线规划失败');
                    }
                }
            );
            const viaMarker = new AMap.Marker({
                position: new AMap.LngLat(105.34247 , 34.73830),
                // 将一张图片的地址设置为 icon
                icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
                // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
                offset: new AMap.Pixel(-23, -30)
            });
            // 将 markers 添加到地图
            this.map.add([viaMarker]);
        })
    },
    methods: {
        back() {
            this.$router.push('vehicleTracking')
        },
        selectRoad(road) {
            let waypoints = ""
            let viaMarker = ""
            switch(road) {
                case 1:
                    waypoints = [
                        new AMap.LngLat(105.34247 , 34.73830), // 甘谷
                        new AMap.LngLat(105.24900 , 34.75189), // 甘谷收费站
                        new AMap.LngLat(105.11528 , 34.76269), // 磐安县
                        new AMap.LngLat(105.24307 , 34.68282), // 武家河镇
                        new AMap.LngLat(105.34247 , 34.73830) // 甘谷
                    ]
                    viaMarker = new AMap.Marker({
                        position: new AMap.LngLat(105.24900 , 34.75189),
                        // 将一张图片的地址设置为 icon
                        icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
                        // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
                        offset: new AMap.Pixel(-13, -30)
                    });
                    break
                case 2:
                    waypoints = [
                        new AMap.LngLat(105.34247 , 34.73830), // 甘谷
                        new AMap.LngLat(105.32934 , 34.78147), // 新兴
                        new AMap.LngLat(105.28311 , 34.88875), // 安远
                        new AMap.LngLat(105.19431 , 34.90548), // 大石
                        new AMap.LngLat(105.09195 , 34.91798), // 礼辛
                        new AMap.LngLat(105.17758 , 34.81920), // 谢家湾
                        new AMap.LngLat(105.34247 , 34.73830), // 甘谷
                    ]
                    viaMarker = new AMap.Marker({
                        position: new AMap.LngLat(105.28311 , 34.88875),
                        // 将一张图片的地址设置为 icon
                        icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
                        // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
                        offset: new AMap.Pixel(-13, -30)
                    });
                    break
                case 3:
                    waypoints = [
                        new AMap.LngLat(105.34247 , 34.73830), // 甘谷
                        new AMap.LngLat(105.45294 , 34.82090), // 金山
                        new AMap.LngLat(105.45966 , 34.91019), // 西坪
                        new AMap.LngLat(105.35842 , 34.98412), // 大庄
                        new AMap.LngLat(105.37269 , 34.85523), // 八里湾
                        new AMap.LngLat(105.34247 , 34.73830), // 甘谷
                    ]
                    viaMarker = new AMap.Marker({
                        position: new AMap.LngLat(105.35842 , 34.98412),
                        // 将一张图片的地址设置为 icon
                        icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
                        // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
                        offset: new AMap.Pixel(-13, -30)
                    });
                    break
            }
            this.driving.clear();
            this.driving.search(
                new AMap.LngLat(105.34247, 34.73830), // 起点
                new AMap.LngLat(105.34247, 34.73830), // 终点
                { waypoints: waypoints },
                function (status, result) {
                    console.log(status,result)
                    if (status === 'complete') {
                        console.log('路线规划成功');
                    } else {
                        console.log('路线规划失败');
                    }
                }
            );
            viaMarker = new AMap.Marker({
                position: new AMap.LngLat(105.34247 , 34.73830),
                // 将一张图片的地址设置为 icon
                icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
                // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
                offset: new AMap.Pixel(-23, -30)
            });
            this.map.add([viaMarker]);
        }
    }
}
</script>
<style>
.el-button {
    margin-bottom: 20px;
}

.amap-logo {
    opacity: 0;
}

.amap-copyright {
    opacity: 0;
}
</style>
  